<template>
  <div class="col-12 col-lg-6 c g">
    <br><br>
    <h2 v-if="school_name" class="g col-12 text-center text-muted">
        {{ school_name }}
    </h2>
    <div class="card">
        <div class="card-header text-center">
            <img :src="require('@/assets/images/loading.svg')" v-if="loading" class="c" style="width: 100px">
            <div v-if="!loading && name" class="text-center col-12">
                <img :src="require('@/assets/images/result.gif')" style="width: 50px; position: relative; top: -5px">
                    تم العثور على شهادة الطالب
                    <img :src="require('@/assets/images/result.gif')" style="width: 50px; position: relative; top: -5px">
                <h3 class="text-center">
                    {{ name }}
                </h3>
            </div>
        </div>
        <div class="card-body" v-if="!loading && name">
            <div class="row">
                <div class="col-12 col-lg-6 g">
                    <a :href="`${api}/results/${id}/${page}.pdf`" target="_blank" class="btn btn-primary btn-block">
                        <i class="fa fa-eye"></i>
                        عرض الشهادة
                    </a>
                </div>
                <div class="col-12 col-lg-6 g">
                    <a :href="`${api}/results/${id}/${page}.pdf`" :download="name + '.pdf'" class="btn btn-success btn-block">
                        <i class="fa fa-download"></i>
                        تحميل الشهادة
                    </a>
                </div>
                <div class="col-12 g text-center">
                     <!-- AddToAny BEGIN -->
                    <div>
                    <a :href="`https://www.addtoany.com/share#url=${encodeURI(link)}&amp;title=شهادة${encodeURI(' ' + name)}`" target="_blank"><img src="https://static.addtoany.com/buttons/a2a.svg" width="32" height="32" style="background-color:royalblue"></a>
                    <a :href="`https://www.addtoany.com/add_to/whatsapp?linkurl=${encodeURI(link)}&amp;linkname=شهادة${encodeURI(' ' + name)}`" target="_blank"><img src="https://static.addtoany.com/buttons/whatsapp.svg" width="32" height="32" style="background-color:#9BCF53"></a>
                    <a :href="`https://www.addtoany.com/add_to/telegram?linkurl=${encodeURI(link)}&amp;linkname=شهادة${encodeURI(' ' + name)}`" target="_blank"><img src="https://static.addtoany.com/buttons/telegram.svg" width="32" height="32" style="background-color:#40A2D8"></a>
                    <a :href="`https://www.addtoany.com/add_to/facebook?linkurl=${encodeURI(link)}&amp;linkname=شهادة${encodeURI(' ' + name)}`" target="_blank"><img src="https://static.addtoany.com/buttons/facebook.svg" width="32" height="32" style="background-color:#40A2E3"></a>
                    <a :href="`https://www.addtoany.com/add_to/x?linkurl=${encodeURI(link)}&amp;linkname=شهادة${encodeURI(' ' + name)}`" target="_blank"><img src="https://static.addtoany.com/buttons/x.svg" width="32" height="32" style="background-color:#000000"></a>
                    <a :href="`https://www.addtoany.com/add_to/twitter?linkurl=${encodeURI(link)}&amp;linkname=شهادة${encodeURI(' ' + name)}`" target="_blank"><img src="https://static.addtoany.com/buttons/twitter.svg" width="32" height="32" style="background-color:#0B60B0"></a>
                    </div>
                    <!-- AddToAny END -->
                </div>
            </div>
        </div>
    </div>
    <div class="text-muted text-center">
        تم ارسال هذه الشهادة بواسطة ادارة المدرسة عن طريق خدمة ارسال الشهادات الموجودة بنظام التحضير الذكي <br> ملف الشهادة يتم ارساله كما هو فى نظام نور
        <br>
        <a href="https://tahdir.net" target="_blank">
            <i class="fa fa-external-link"></i>
            tahdir.net - التحضير الذكي
        </a>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            page: 0,
            name: null,
            api: api,
            link: window.location.href,
            school_name: false
        }
    },
    created(){
        var g = this;
         $.post(api + '/user/results/result', {
            id: window.location.href?.split("/result/")[1]?.split("/")[0],
            number: window.location.href?.split("/result/")[1]?.split("/")[1],
        }).then(function(r){
            g.id = window.location.href?.split("/result/")[1]?.split("/")[0];
            g.loading = false;
            if(r.status != 100){
                alert(r.response, 200)
            }else{
                g.page = r.response.page
                g.name = r.response.name
                g.school_name = r.response.school_name
            }
        }).fail(function(){
                g.loading = false;
            alert("حدث خطأ", 200)
        })
    }
}
</script>

<style>

</style>